import React from "react"
import PropTypes from "prop-types"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import { Link } from "gatsby"
import ImageMeta from "../../components/ImageMeta"
import ButtonGroupMap from "../../components/Button/ButtonGroupMap"

const classNames = require("classnames")

// Only is set up for 2 or 5 docs
function MeetOurDoctors({ post, colorBack, language }) {
  if (!post.meetOurDoctors.hasThisSection) return <></>
  const data = post.meetOurDoctors
  const docLength = data.images ? data.images.length : 0
  const showDoctorImages = !data.useButtons && data.images
  const mainClasses = classNames("body-section", "doc-info", {
    "color-back": colorBack
  })

  const esMainClasses = classNames("doc-info-es", "body-section", "doc-info", {
    "color-back": !language === "es"
  })

  const generateDocChunks = () => {
    if (!data.images) return
    // split doc images into 2 arrays
    const docChunks = [[], []]

    // if 4 or 5 doctors, top row gets 2. else show 3
    let arrayLimit = 3
    if (docLength === 5 || docLength === 4) {
      arrayLimit = 2
    }

    data.images.forEach((dr, i) => {
      if (docChunks[0].length >= arrayLimit) {
        docChunks[1].push(dr)
      } else {
        docChunks[0].push(dr)
      }
    })
    return docChunks
  }

  return (
    <section className={` ${language === "es" ? esMainClasses : mainClasses}`}>
      <div className="columns">
        <div className="column is-4" />
        <div className="column has-text-centered-tablet">
          <MarkdownViewer markdown={data.text} />
        </div>
        <div className="column is-4" />
      </div>
      {data.useButtons && <ButtonGroupMap buttons={data.buttons} />}

      {showDoctorImages && (
        <div className={`our-docs is-centered has-${docLength}-docs`}>
          <DoctorImages doctors={generateDocChunks()} />
        </div>
      )}
    </section>
  )
}

MeetOurDoctors.propTypes = {
  post: PropTypes.object.isRequired,
  colorBack: PropTypes.bool,
  language: PropTypes.string
}

function DoctorImages({ doctors }) {
  const styles = {
    captions: {
      display: "flex",
      justifyContent: "space-between"
    },
    learnMore: {
      fontWeight: 600,
      fontSize: "14px",
      textDecoration: "underline"
    },
    docName: {
      fontSize: "14px"
    }
  }

  const columnClasses = classNames("column", "doc-column", {
    "is-8": doctors[0].length === 1,
    "is-7": doctors[0].length === 2,
    "is-6": doctors[0].length >= 3
  })

  return (
    <>
      <div className="columns is-centered">
        {doctors[0] &&
          doctors[0].map((image, i) => (
            <React.Fragment key={i}>
              <div className={columnClasses}>
                <Link to={image.doctor.url}>
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={image.doctor.imageId}
                    width="auto"
                    responsive
                    className="meet-our-doctor"
                  />
                </Link>

                <div className="docs-2-3-caption" style={styles.captions}>
                  <Link to={image.doctor.url}>
                    <h5 className="image-caption doctor" style={styles.docName}>
                      {image.doctor.caption}
                    </h5>
                  </Link>

                  <Link to={image.doctor.url}>
                    <h5
                      className="image-caption doctor learn"
                      style={styles.learnMore}>
                      Learn More
                    </h5>
                  </Link>
                </div>
              </div>
              {i !== doctors[0].length - 1 && <div className="column is-1" />}
            </React.Fragment>
          ))}
      </div>

      {doctors[1].length >= 1 && (
        <>
          <div className="mt-40__desktop" />

          <div className="columns is-centered">
            {doctors[1] &&
              doctors[1].map((image, i) => (
                <React.Fragment key={i}>
                  <div className={columnClasses}>
                    <Link to={image.doctor.url}>
                      <ImageMeta
                        cloudName="nuvolum"
                        publicId={image.doctor.imageId}
                        width="auto"
                        responsive
                        className="meet-our-doctor"
                      />
                    </Link>

                    <div className="docs-2-3-caption" style={styles.captions}>
                      <Link to={image.doctor.url}>
                        <h5
                          className="image-caption doctor"
                          style={styles.docName}>
                          {image.doctor.caption}
                        </h5>
                      </Link>

                      <Link to={image.doctor.url}>
                        <h5
                          className="image-caption doctor learn"
                          style={styles.learnMore}>
                          Learn More
                        </h5>
                      </Link>
                    </div>
                  </div>
                  {i !== doctors[1].length - 1 && (
                    <div className="column is-1" />
                  )}
                </React.Fragment>
              ))}
          </div>
        </>
      )}
    </>
  )
}

DoctorImages.propTypes = {
  doctors: PropTypes.array
}

export default MeetOurDoctors
